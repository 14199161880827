export default class Carousel
{
    constructor( element, arrowNext, arrowPrev )
    {
        if ( element === null || element.length < 1 ) {
            return;
        }

        console.log( "initialize carousel" );
        const self = this;
        this.element = element;
        this.position = parseInt( element.getAttribute( "data-position" ) );
        this.slideCount = element.getElementsByClassName( "slide" ).length;
        this.arrowNext = arrowNext;
        this.arrowPrev = arrowPrev;

        console.log('arrows', arrowNext, arrowPrev);

        if ( arrowNext !== null ) {
            console.log( "register next arrow handler" );
            arrowNext.addEventListener( "click", function ( e ) {
                console.log('click next');
                self.nextArrowHandler( e );
            } );
        }

        if ( arrowPrev !== null ) {
            console.log( "register prev arrow handler" );
            arrowPrev.classList.add("hidden");
            arrowPrev.addEventListener( "click", function ( e ) {
                console.log('click prev');
                self.prevArrowHandler( e );
            } );
        }
    }

    nextArrowHandler( e )
    {
        this.setPosition( this.position + 1 );
        e.preventDefault();
    }

    prevArrowHandler( e )
    {
        this.setPosition( this.position - 1 );
        e.preventDefault();
    }

    setPosition( position )
    {

        if ( position < 0 || position > ( this.slideCount - 2 ) ) {
            return; // position is out of range
        }

        if ( position === 0 ) {
            this.arrowPrev.classList.add( "hidden" );
            console.log("hide prev arrow");
        } else {
            this.arrowPrev.classList.remove( "hidden" );
        }

        console.log(position, ( this.slideCount - 3 ));
        if ( position > ( this.slideCount - 3 ) ) {
            this.arrowNext.classList.add( "hidden" );
            console.log("hide next arrow");
        } else {
            this.arrowNext.classList.remove( "hidden" );
        }

        this.position = position;
        this.element.setAttribute( "data-position", position );
    }
}