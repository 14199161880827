export default class Block
{
    constructor( icon, backgroundColor, foregroundColor )
    {
        this.icon = icon;
        this.backgroundColor = backgroundColor;
        this.foregroundColor = foregroundColor;
        this.element = null;
    }

    /**
     *
     * @returns {HTMLElement}
     */
    createElement()
    {
        const block = document.createElement( "div" );
        block.classList.add( "block" );
        block.classList.add( "bg-" + this.getBackgroundColor() );
        block.classList.add( "fg-" + this.getForegroundColor() );
        block.classList.add( "load-icon" );
        block.setAttribute( "data-icon", this.getIcon() );

        const blockInner = document.createElement( "div" );
        blockInner.classList.add( "block-inner" );
        block.appendChild( blockInner );


        // const icon = document.createElement("span");
        // icon.classList.add("icon-" + this.getIcon());
        // blockInner.appendChild(icon);

        this.element = block;

        return this.getElement();
    }

    getElement()
    {
        return this.element;
    }

    removeElement()
    {
        if ( this.element !== null ) {
            this.element.remove();
            this.element = null;
        }
    }

    getIcon()
    {
        return this.icon;
    }

    setIcon( icon )
    {
        this.icon = icon;

        return this;
    }

    getBackgroundColor()
    {
        return this.backgroundColor;
    }

    setBackgroundColor( backgroundColor )
    {
        this.backgroundColor = backgroundColor;

        return this;
    }

    getForegroundColor()
    {
        return this.foregroundColor;
    }

    setForegroundColor( foregroundColor )
    {
        this.foregroundColor = foregroundColor;

        return this;
    }
}