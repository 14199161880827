import BlockGridGenerator from "./block-grid-generator";
import Carousel from "./carousel";
import IconLoader from "./icons/icon-loader";

const generator = new BlockGridGenerator( false, {
    icons: [
        'circle-square',
        'line-chart',
        'pen',
        'pcb',
        'nut',
        'bar-chart',
        'ghost',
        'diagonal',
        'compass',
        'stripes',
        'doughnut',
        'radio',
        'circle',
        'tie',
        'hamburger',
        'plug'
    ]
} );

if ( typeof blockGrids !== "undefined" && Array.isArray( blockGrids ) ) {
    for ( let i = 0; i < blockGrids.length; i++ ) {
        createBlockGrid( blockGrids[ i ] );
    }
}

function createBlockGrid( options )
{
    if ( !validateOptions( options ) ) {
        return;
    }

    const container = document.getElementById( options.id );

    if ( container.length < 1 ) {
        return;
    }

    const grid = generator.generate( options.id, options.options, options.template );
    container.appendChild( grid.createElement() );
    grid.renderBlocks();
}

function validateOptions( options )
{
    if ( !options.hasOwnProperty( "id" ) ) {
        return false;
    }

    if ( !options.hasOwnProperty( "template" ) ) {
        return false;
    }

    return options.hasOwnProperty( "options" );
}

(function () {
    const iconLoader = new IconLoader("https://www.webwijs.nu/wp-content/themes/webwijs-theme/assets/img/svg/grid");
    iconLoader.loadIcons();
})();



