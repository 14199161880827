export default class IconLoader
{
    constructor( baseUrl )
    {
        this.baseUrl = baseUrl;
        this.icons = {};
        this.delay = 100;
    }

    /**
     *
     * @param block
     */
    loadIcon( block )
    {
        const self = this;
        const iconName = block.getAttribute( "data-icon" );
        if ( iconName === null || iconName.length < 1 ) {
            return;
        }

        if ( this.icons.hasOwnProperty( iconName ) ) {
            self.setBlockContent( block, self.icons[ iconName ] );
        }

        const url = this.baseUrl + "/" + iconName + ".svg";

        const oReq = new XMLHttpRequest();
        oReq.addEventListener( "load", function () {
            self.icons[ iconName ] = this.responseText;
            self.setBlockContent( block, this.responseText );

        } );
        oReq.open( "GET", url );
        oReq.send();
    }

    setBlockContent( block, content )
    {
        block.innerHTML = content;
        const self = this;
        setTimeout( function () {
            block.classList.add( "loaded-icon" );
            self.delay += 50;
        }, self.delay );

    }

    loadIcons()
    {
        const blocks = document.getElementsByClassName( "load-icon" );
        for ( let i = 0; i < blocks.length; i++ ) {
            this.loadIcon( blocks[ i ] );
        }
    }
}