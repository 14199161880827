import Block from "./block";
import BlockGrid from "./block-grid";

export default class BlockGridGenerator
{
    constructor( debug, options )
    {
        const colors = ["black", "white", "green", "blue", "red"];
        this.options = Object.assign( {
            "icons": ["empty"],
            "bgColors": colors,
            "fgColor": colors,
        }, options );

        this.debug = debug;
    }

    generate( gridId, gridOptions, blocksTemplate )
    {
        gridOptions.width = this._getTemplateWidth( blocksTemplate );
        gridOptions.height = this._getTemplateHeight( blocksTemplate );

        const blocks = this._generateBlocks( blocksTemplate );


        return this._createGrid( gridId, gridOptions, blocks );
    }

    _getTemplateWidth( template )
    {
        let width = 0;

        for ( let i = 0; i < template.length; i++ ) {
            const row = template[ i ];
            if ( row.length > width ) {
                width = row.length;
            }
        }

        return width;
    }

    _getTemplateHeight( template )
    {
        return template.length;
    }

    _generateBlocks( template )
    {
        const blocks = [];

        for ( let y = 0; y < this._getTemplateHeight( template ); y++ ) {
            blocks[ y ] = [];
            for ( let x = 0; x < template[ y ].length; x++ ) {
                if ( template[ y ][ x ] === null ) {
                    blocks[ y ][ x ] = null;
                } else {
                    blocks[ y ][ x ] = this._uniqueBlock( blocks, template, x, y );
                }
            }
        }

        return blocks;
    }

    _uniqueBlock( blocks, template, positionX, positionY )
    {
        const blockTemplate = template[ positionY ][ positionX ];
        let block = this._generateBlock( blockTemplate );

        if ( block === null ) {
            return block;
        }

        let neighbors = this._getAdjacentBlocks( blocks, positionX, positionY );

        const adjacentBgColors = this._getBlocksBackgroundColors( neighbors );
        const backgroundSameAsAdjacent = adjacentBgColors.includes( block.getBackgroundColor() );
        const backgroundIsRandom = !blockTemplate.hasOwnProperty( "bgColor" );

        if ( backgroundSameAsAdjacent && backgroundIsRandom ) {
            block.setBackgroundColor( this._getRandomBgColor( adjacentBgColors ) );
        }

        const adjacentFgColors = this._getBlocksForegroundColors( neighbors );
        const foregroundSameAsAdjacent = adjacentFgColors.includes( block.getForegroundColor() );
        const foregroundSameAsBackground = block.getBackgroundColor() === block.getForegroundColor();
        const foregroundIsRandom = !blockTemplate.hasOwnProperty( "fgColor" );

        if ( ( foregroundSameAsAdjacent || foregroundSameAsBackground ) && foregroundIsRandom ) {
            adjacentFgColors.push( block.getBackgroundColor() );
            block.setForegroundColor( this._getRandomFgColor( adjacentFgColors ) );
        }

        const adjacentIcons = this._getBlocksIcons( neighbors );
        const hasIcon = block.getIcon().length > 0;
        const iconSameAsAdjacent = adjacentIcons.includes( block.getIcon() );
        const iconIsRandom = !blockTemplate.hasOwnProperty( "icon" );

        if ( hasIcon && iconSameAsAdjacent && iconIsRandom ) {
            block.setIcon( this._getRandomIcon( adjacentIcons ) );
        }

        return block;
    }

    _getAdjacentBlocks( blocks, positionX, positionY )
    {

        const adjacent = [];
        this.log( 'Block: ' + positionX + ',' + positionY );

        const north = this.getBlock( blocks, positionX, positionY - 1 );
        let n_message = '';
        if ( north !== null ) {
            adjacent.push( north );
            n_message = ' (bg: ' + north.backgroundColor + ', fg: ' + north.foregroundColor + ')';
        }
        this.log( '    North: ' + positionX + ',' + ( positionY - 1 ) + n_message );

        const east = this.getBlock( blocks, positionX + 1, positionY );
        if ( east !== null ) {
            adjacent.push( east );
        }

        const south = this.getBlock( blocks, positionX, positionY + 1 );
        if ( south !== null ) {
            adjacent.push( south );
        }

        const west = this.getBlock( blocks, positionX - 1, positionY );
        let w_message = '';
        if ( west !== null ) {
            adjacent.push( west );
            w_message = ' (bg: ' + west.backgroundColor + ', fg: ' + west.foregroundColor + ')';
        }
        this.log( '    West: ' + ( positionX - 1 ) + ',' + positionY + w_message );

        this.log( ' ' );
        return adjacent;
    }

    _getBlocksBackgroundColors( adjacentBlocks )
    {
        const backgroundColors = [];

        for ( let i = 0; i < adjacentBlocks.length; i++ ) {
            backgroundColors.push( adjacentBlocks[ i ].backgroundColor );
        }

        return backgroundColors;
    }

    _getBlocksForegroundColors( adjacentBlocks )
    {
        const foregroundColors = [];

        for ( let i = 0; i < adjacentBlocks.length; i++ ) {
            foregroundColors.push( adjacentBlocks[ i ].foregroundColor );
        }

        return foregroundColors;
    }

    _getBlocksIcons( adjacentBlocks )
    {
        const icons = [];

        for ( let i = 0; i < adjacentBlocks.length; i++ ) {
            icons.push( adjacentBlocks[ i ].icon );
        }

        return icons;
    }

    _generateBlock( blockTemplate )
    {
        if ( blockTemplate !== null ) {
            const ic = this._getIcon( blockTemplate );
            const bg = this._getBgColor( blockTemplate );
            const fg = this._getFgColor( blockTemplate );
            if ( !blockTemplate.hasOwnProperty( "bgColor" ) || !blockTemplate.hasOwnProperty( "fgColor" ) ) {
                if ( bg === fg ) {
                    return this._generateBlock( blockTemplate );
                }
            }
            return new Block( ic, bg, fg );
        }
        return null;
    }

    _generateRandomBlock()
    {
        return new Block( this._getRandomIcon(), this._getRandomBgColor(), this._getRandomFgColor() );
    }

    _getIcon( blockTemplate )
    {
        if ( !blockTemplate.hasOwnProperty( "icon" ) ) {
            const icon = this._getRandomIcon();
            // blockTemplate.icon = icon;
            return icon;
        }

        if ( blockTemplate.icon === null ) {
            return "";
        }

        return blockTemplate.icon;
    }

    _getRandomIcon( exclude )
    {
        exclude = ( typeof exclude !== "undefined" ) ? exclude : []
        return this._getRandomArrayEntry( this.options.icons, exclude );
    }

    _getBgColor( blockTemplate )
    {
        if ( !blockTemplate.hasOwnProperty( "bgColor" ) ) {
            const bgColor = this._getRandomBgColor();
            // blockTemplate.bgColor = bgColor;
            return bgColor;
        }

        if ( blockTemplate.bgColor === null ) {
            return "transparent";
        }

        return blockTemplate.bgColor;
    }

    _getRandomBgColor( exclude )
    {
        exclude = ( typeof exclude !== "undefined" ) ? exclude : []
        return this._getRandomArrayEntry( this.options.bgColors, exclude );
    }

    _getFgColor( blockTemplate )
    {
        if ( !blockTemplate.hasOwnProperty( "fgColor" ) ) {
            const fgColor = this._getRandomFgColor();
            // blockTemplate.fgColor = fgColor;
            return fgColor;
        }

        if ( blockTemplate.fgColor === null ) {
            return "transparent";
        }

        return blockTemplate.fgColor;
    }

    _getRandomFgColor( exclude )
    {
        exclude = ( typeof exclude !== "undefined" ) ? exclude : []
        return this._getRandomArrayEntry( this.options.fgColor, exclude );
    }

    _getRandomArrayEntry( array, exclude )
    {
        const availableEntries = this._arrayDiff( array, exclude );
        return availableEntries[ ~~( availableEntries.length * Math.random() ) ];
    }

    _arrayDiff( array1, array2 )
    {
        return array1.filter( x => !array2.includes( x ) );
    }

    /**
     * Get a block from a specific position
     *
     * @param {Block[][]} blocks
     * @param {number} positionX the column to get the block from
     * @param {number} positionY the row to get the block from
     * @returns {Block} the retrieved block
     */
    getBlock( blocks, positionX, positionY )
    {
        if ( typeof blocks[ positionY ] !== "undefined" ) {
            if ( typeof blocks[ positionY ][ positionX ] !== "undefined" ) {
                return blocks[ positionY ][ positionX ];
            }
        }
        return null;
    }

    _createGrid( gridId, gridOptions, blocks )
    {
        const grid = new BlockGrid( gridId, gridOptions, this.debug );
        grid.setBlocks( blocks );
        return grid;
    }

    log( message )
    {
        if ( this.debug === true ) {
            // console.log(message);
        }
    }
}