import Block from "./block";

export default class BlockGrid
{
    /**
     * BlockGrid constructor
     *
     * @param {string} id
     * @param {object} options
     * @param debug
     * @returns {BlockGrid} self for chaining method calls
     */
    constructor( id, options, debug )
    {
        this.id = id;
        this.debug = debug;

        this.options = Object.assign( {
            width: 16,
            height: 16,
            columnWidth: {
                "small": 8,
                "medium": 4,
                "large": 1,
            }
        }, options );

        // Initialize the array to keep the actual HTMLElement objects of the columns.
        // Used to clear or assign content to them later on.
        this.columns = [];

        this.blocks = [];
        // Initialize multidimensional array for blocks. Creates an array for each column to hold the individual blocks.
        // The position of the blocks in the multidimensional array, represents the position of the blocks in the grid.
        for ( let y = 0; y < this.options.height; y++ ) {
            this.blocks.push( [] );
        }

        return this;
    }

    /**
     * Create the HTMLElement
     *
     * @returns {HTMLElement}
     */
    createElement()
    {
        const grid = this._createGrid();
        for ( let x = 0; x < this.options.width; x++ ) {
            const col = this._createColumn(x);
            for ( let y = 0; y < this.options.height; y++ ) {
                col.appendChild( this._createCell( x, y ) );
            }
            this.columns.push( col );
            grid.appendChild( col );
        }

        return grid;
    }

    /**
     * Set a block to a position
     *
     * @param {Block} block
     * @param {number} positionX the column to set the block to
     * @param {number} positionY the row to set the block to
     * @returns {BlockGrid} self for chaining method calls
     */
    setBlock( block, positionX, positionY )
    {
        if ( !block instanceof Block ) {
            throw new Error( "Argument 1 of BlockGrid.setBlock() must be an instance of Block" );
        }
        if ( typeof this.blocks[ positionX ] === "undefined" ) {
            throw new Error( "There is no column at position " + positionX );
        }

        this.blocks[ positionY ][ positionX ] = block;

        return this;
    }

    /**
     * Get a block from a specific position
     *
     * @param {number} positionX the column to get the block from
     * @param {number} positionY the row to get the block from
     * @returns {Block} the retrieved block
     */
    getBlock( positionX, positionY )
    {
        if ( typeof this.blocks[ positionY ] === "undefined" || typeof this.blocks[positionY][ positionX ] === "undefined" ) {
            return null;
        }

        return this.blocks[ positionY ][ positionX ];
    }

    /**
     * Set a multidimensional array of blocks
     *
     * @param {Block[][]} blocks the array of blocks to set
     * @returns {BlockGrid} self for chaining method calls
     */
    setBlocks( blocks )
    {
        this.blocks = blocks;

        return this;
    }

    /**
     * Clear a cell on a specific position
     *
     * @param {number} positionX the column of the cell
     * @param {number} positionY the row of the cell
     * @returns {BlockGrid} self for chaining method calls
     */
    clearCell( positionX, positionY )
    {
        const cell = this.getCell( positionX, positionY );
        cell.innerHTML = "";

        return this;
    }

    /**
     * Get a cell on a specific position
     *
     * @param {number} positionX the column of the cell
     * @param {number} positionY the row of the cell
     * @returns {Element}
     */
    getCell( positionX, positionY )
    {
        const cell = document.querySelector( "#block-grid-" + this.id + " .square-x-" + positionX + ".square-y-" + positionY );
        // console.log(cell);
        if ( cell === null ) {
            throw new Error( "There is no cell at column " + positionX + "and row " + positionY );
        }

        return cell;
    }

    /**
     * Clear all cells
     *
     * @returns {BlockGrid} self for chaining method calls
     */
    clearCells()
    {
        for ( let i = 0; i < this.columns.length; i++ ) {
            this.columns[ i ].innerHTML = "";
        }

        return this;
    }

    /**
     * Render all blocks
     *
     * @returns {BlockGrid} self for chaining method calls
     */
    renderBlocks()
    {
        // console.log(this.blocks.length);
        for ( let y = 0; y < this.blocks.length; y++ ) {
            for ( let x = 0; x < this.blocks[ y ].length; x++ ) {
                // console.log(y, x);
                this.renderBlockByPosition( x, y );
            }
        }

        return this;
    }

    /**
     * Render a block on a specific position
     *
     * @param positionX the column of the block
     * @param positionY the row of the block
     * @returns {BlockGrid} self for chaining method calls
     */
    renderBlockByPosition( positionX, positionY )
    {
        // console.log("render" + positionX + ", " + positionY);
        const cell = this.getCell( positionX, positionY );

        if ( cell === null ) {
            // console.log('not found cell: '+positionX+','+positionY);
            return this;
        }

        const block = this.getBlock( positionX, positionY );
        // console.log(block);
        if ( block === null ) {
            // console.log('not found block: '+positionX+','+positionY);
            return this;
        }

        cell.innerHTML = "";
        cell.appendChild( block.createElement() );

        if(this.debug === true){
            const count = document.createElement( "span" );
            count.classList.add( "count" );
            count.innerText = positionX+", "+positionY;
            cell.appendChild(count);
        }

        return this;
    }

    /**
     * Creates a grid container HTML element
     *
     * @returns {HTMLDivElement} the newly created container element
     * @private
     */
    _createGrid()
    {
        const grid = document.createElement( "div" );

        grid.setAttribute( "id", "block-grid-" + this.id );

        grid.classList.add( "grid-x" );
        grid.classList.add( "block-grid" );

        return grid;
    }

    /**
     * Creates a grid column HTML element
     *
     * @returns {HTMLDivElement} the newly created column element
     * @private
     */
    _createColumn(colIndex)
    {
        const col = document.createElement( "div" );

        col.classList.add( "cell" );
        for ( const [breakpoint, width] of Object.entries( this.options.columnWidth ) ) {
            col.classList.add( breakpoint + "-" + width );

        }

        if(colIndex > 7){
            col.classList.add( "show-for-large" );
        }
        
        return col;
    }

    /**
     * Creates a grid cell HTML element
     *
     * @param {number} positionX the column of the grid cell
     * @param {number} positionY the row of the grid cell
     * @returns {HTMLDivElement} the newly created cell element
     * @private
     */
    _createCell( positionX, positionY )
    {
        const cell = document.createElement( "div" );

        cell.classList.add( "square" );
        cell.classList.add( "square-x-" + positionX );
        cell.classList.add( "square-y-" + positionY );
        cell.classList.add( "small-ratio-1-1" );

        return cell;
    }
}